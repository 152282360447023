var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_vm._v(" VIAJANTES (edição pra versão antiga) ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(_vm.serviceTravellers.length < 10)?_c('a-button',{staticClass:"add-button",attrs:{"size":"small"},on:{"click":function($event){return _vm.updateServiceTraveller(_vm.serviceTravellers.length)}}},[_vm._v("\n          Adicionar\n        ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.serviceTravellers),function(traveller,index){return _c('a-row',{key:index,staticClass:"travellers-rows",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":1}},[_c('font',{staticClass:"user"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "),_c('font',{staticClass:"number"},[_vm._v("\n          "+_vm._s(traveller.id)+"\n        ")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{staticClass:"travel-input"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `service_traveller_${traveller.id}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `service_traveller_${traveller.id}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecione um viajante"}},_vm._l((_vm.travellers),function(traveller){return _c('a-select-option',{key:traveller,attrs:{"value":traveller.id}},[_vm._v("\n            "+_vm._s(traveller.name)+"\n            "+_vm._s(traveller.lastName)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[_c('a-tooltip',{attrs:{"placement":"topRight","title":"Adicionar todos viajantes"}},[(traveller.id == 1)?_c('a-button',{staticStyle:{"position":"relative","top":"10px"},attrs:{"size":"small","type":"primary","shape":"circle"},on:{"click":function($event){return _vm.addAllTravellers(1, 'service')}}},[_c('a-icon',{staticStyle:{"font-size":"17px"},attrs:{"type":"usergroup-add"}})],1):_vm._e()],1),_vm._v(" "),(
          traveller.id == _vm.serviceTravellers.length &&
          _vm.serviceTravellers.length != 1
        )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeServiceTraveller(traveller.id)}}}):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }