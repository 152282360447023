<template>
  <div>
    <h3 class="title">
      <a-row>
        <a-col :span="12"> VIAJANTES (edição pra versão antiga) </a-col>
        <a-col class="a-right" :span="12">
          <a-button
            v-if="serviceTravellers.length < 10"
            size="small"
            class="add-button"
            @click="updateServiceTraveller(serviceTravellers.length)"
          >
            Adicionar
          </a-button>
        </a-col>
      </a-row>
    </h3>

    <a-row
      v-for="(traveller, index) in serviceTravellers"
      :key="index"
      :gutter="20"
      class="travellers-rows"
    >
      <a-col :span="1">
        <font class="user">
          <a-icon type="user" />
          <font class="number">
            {{ traveller.id }}
          </font>
        </font>
      </a-col>

      <a-col :span="10">
        <a-form-item class="travel-input">
          <a-select
            placeholder="Selecione um viajante"
            v-decorator="[
              `service_traveller_${traveller.id}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="traveller of travellers"
              :key="traveller"
              :value="traveller.id"
            >
              {{ traveller.name }}
              {{ traveller.lastName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="1">
        <a-tooltip placement="topRight" title="Adicionar todos viajantes">
          <a-button
            v-if="traveller.id == 1"
            size="small"
            type="primary"
            shape="circle"
            style="position: relative; top: 10px"
            @click="addAllTravellers(1, 'service')"
          >
            <a-icon type="usergroup-add" style="font-size: 17px" />
          </a-button>
        </a-tooltip>

        <a-button
          v-if="
            traveller.id == serviceTravellers.length &&
            serviceTravellers.length != 1
          "
          size="small"
          type="danger"
          shape="circle"
          icon="delete"
          @click="removeServiceTraveller(traveller.id)"
        >
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "ServiceTravellers",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    travellers: Array,
  },
  data() {
    return {
      serviceTravellers: [],
    };
  },
  beforeMount() {
    this.serviceTravellers = this.tempContract.service_travellers
      ? JSON.parse(this.tempContract.service_travellers)
      : [];
  },
  methods: {
    updateServiceTraveller(id) {
      this.serviceTravellers.push({
        id: id + 1,
      });

      this.tempContract["service_travellers"] = JSON.stringify(
        this.serviceTravellers
      );
    },
    removeServiceTraveller(id) {
      for (var i in this.serviceTravellers) {
        if (this.serviceTravellers[i].id == id) {
          this.serviceTravellers.splice(i, 1);
          break;
        }
      }
      this.tempContract["service_travellers"] = JSON.stringify(
        this.serviceTravellers
      );
    },
    addAllTravellers(id, type) {
      let travellers = [];

      this.travellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName)
          travellers.push({ id: traveller.id });
      });

      if (travellers.length > 0) {
        if (type == "service") {
          this.serviceTravellers = travellers;

          setTimeout(() => {
            travellers.forEach((traveller) => {
              this.form.setFieldsValue({
                [`service_traveller_${traveller.id}`]: traveller.id,
              });
            });
          }, 20);

          this.tempContract.service_travellers = JSON.stringify(travellers);
        }
      } else {
        this.$message.warning("Preencha os viajantes.");
      }
    },
  },
};
</script>
